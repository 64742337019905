.modal_overlay {
  @apply fixed z-50 flex items-center left-0 top-56;
}

.modal {
  @apply bg-transparent m-0 bg-white outline-none p-4 max-w-xs;
  box-shadow: 0 0 10px -5px rgba(0,0,0,0.75);
}

.modal .modal_body {
  @apply relative text-center;
}

.modal .modal_close {
  @apply absolute bg-blue p-1 z-10 right-0 sm:-right-6 top-0 sm:-top-6;
}

.title {
  @apply font-display font-extrabold text-center mb-2;
}

.image {
  @apply relative overflow-hidden h-40 w-40 mx-auto block;
}

.message {
  @apply mt-2 text-gray;
}

.message p {
  @apply mb-2;
}

.message p:last-child {
  @apply mb-0;
}
