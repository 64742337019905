.ad {
  @apply min-h-[390px];
}

.ad a {
  @apply h-full flex justify-center relative;
}

.cta {
  @apply absolute z-10 bottom-5 text-center text-white py-2 px-5 bg-green rounded-lg inline-block;
}
